/**
 * Created by boris on 5/3/17.
 */
import React from 'react'
import classnames from 'classnames'

import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Alert from 'react-s-alert'

import textToError from '../../utils/textToError'

class ResetPasswordCode extends React.Component {
  componentDidMount() {
    Alert.info('Please check your email for confirmation code', {
      position: 'top-right',
      effect:   'slide',
      beep:     false,
      timeout:  5000,
      offset:   100,
    })
  }

  render() {
    const {
      handleResetPassword,
      errors,
      handleChange,
      code,
      password,
      repeat_password,
      isLoading,
      handleBackClick,
      isValid,
    } = this.props

    console.log('rendering email', errors)

    return (
      <form className="signup-form">
        <TextField
          label="Confirmation code"
          placeholder="Code from email"
          name="code"
          type="text"
          value={code || ''}
          className={classnames({
            'invalid-input': errors.code,
          })}
          {...textToError(errors.code)}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="New password (at least six characters)"
          name="password"
          type="password"
          value={password || ''}
          className={classnames({
            'invalid-input': errors.password,
          })}
          {...textToError(errors.password)}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          label="Repeat new password"
          name="repeat_password"
          type="password"
          value={repeat_password || ''}
          className={classnames({
            'invalid-input': errors.repeat_password,
          })}
          {...textToError(errors.repeat_password)}
          onChange={handleChange}
          fullWidth
        />
        <div className="signup-submit-button">
          <Button
            variant="contained"
            onClick={handleBackClick}
            className="signup-form-back"
          >
            Back
          </Button>
          <Button
            variant="contained"
            disabled={isLoading || !isValid}
            color="primary"
            onClick={handleResetPassword}
            className="send-comment reset-password-submit"
          >
            Submit
          </Button>
        </div>
      </form>
    )
  }
}

export default ResetPasswordCode
