/**
 * Created by boris on 4/27/17.
 */
import React from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

import * as R from 'ramda'
import Paper from '@material-ui/core/Paper'

import ResetPasswordEmail from './ResetPasswordEmail'
import ResetPasswordCode from './ResetPasswordCode'
import { resetPassword } from '../../actions/ResetPasswordActions'
import { NOT_FOUND } from '../../actions/constants'
import {
  ResetPasswordValidation,
  EmailValidation,
  EmailFormValidation,
} from '../../utils/Validations'

import Alert from 'react-s-alert'

import '../../css/SignUp/SignUpForm.css'

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emailForm:       true,
      errors:          {},
      email:           null,
      code:            null,
      password:        null,
      repeat_password: null,
      isValid:         true,
    }

    this.handleEmailNext = this.handleEmailNext.bind(this)
    this.handleResetPassword = this.handleResetPassword.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value }, () => {
      const { email, code, password, repeat_password, emailForm } = this.state
      const { errors, isValid } = emailForm
        ? EmailValidation(email)
        : ResetPasswordValidation({ code, password, repeat_password })
      this.setState({ errors, isValid })
    })
  }

  handleEmailNext() {
    const { resetPassword } = this.props
    const { email } = this.state
    const { isValid, errors } = EmailFormValidation(email)
    if (isValid) {
      resetPassword({ email })
        .then(response => this.setState({ emailForm: false, errors: {} }))
        .catch(error => {
          const message = R.path(['response', 'data', 'error'], error)
          const status = R.path(['response', 'status'], error)

          Alert.error(message, {
            position: 'top-right',
            effect:   'slide',
            beep:     false,
            timeout:  5000,
            offset:   100,
          })

          if (status === NOT_FOUND) {
            this.setState({ errors: { email: message } }) // first page
          }
        })
    } else this.setState({ errors })
  }

  handleResetPassword() {
    const { email, code, password, repeat_password } = this.state
    const { resetPassword } = this.props
    const { isValid, errors } = ResetPasswordValidation(
      { code, password, repeat_password },
      true
    )
    if (isValid) {
      resetPassword({ email, code, password })
        .then(() => {
          Alert.success('Password changed', {
            position: 'top-right',
            effect:   'slide',
            beep:     false,
            timeout:  5000,
            offset:   100,
          })
          browserHistory.push('/login')
        })
        .catch(error => {
          // console.log('error', error)
          // console.log(error)
          // console.log(error.response)

          Alert.error(R.path(['response', 'data', 'error'], error), {
            position: 'top-right',
            effect:   'slide',
            beep:     false,
            timeout:  5000,
            offset:   100,
          })
        })
    } else this.setState({ errors, isValid })
  }

  handleBackClick() {
    this.setState({ emailForm: true })
  }

  render() {
    const { isLoading } = this.props
    const {
      emailForm,
      errors,
      email,
      code,
      password,
      repeat_password,
      isValid,
    } = this.state

    console.log('rendering email parent', errors)

    return (
      <div className="login-page-content">
        <Paper
          elevation={5}
          className="login-form-container login-form-container__reset_password"
        >
          {emailForm ? (
            <ResetPasswordEmail
              handleEmailNext={this.handleEmailNext}
              errors={errors}
              email={email}
              isLoading={isLoading}
              handleChange={this.handleChange}
              isValid={isValid}
            />
          ) : (
            <ResetPasswordCode
              handleResetPassword={this.handleResetPassword}
              errors={errors}
              code={code}
              password={password}
              repeat_password={repeat_password}
              isLoading={isLoading}
              handleChange={this.handleChange}
              handleBackClick={this.handleBackClick}
              isValid={isValid}
            />
          )}
        </Paper>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoading: state.resetPassword.isLoading,
  }
}

export default connect(mapStateToProps, { resetPassword })(ResetPasswordPage)
