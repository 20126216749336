/**
 * Created by boris on 2/22/17.
 */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImageUpload from '../ImageUpload'
import TextField from '@material-ui/core/TextField'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import { connect } from 'react-redux'
import { editProfile } from '../../actions/EditProfileActions'
// import Toggle from '@material-ui/core/Toggle'
import classnames from 'classnames'
import { editValidation } from '../../utils/Validations'
import { checkUsername } from '../../actions/SignUpActions'
import { toggleActivation } from '../../actions/AuthActions'
import isEmpty from 'lodash/isEmpty'
import textToError from '../../utils/textToError'

import Alert from 'react-s-alert'

import { browserHistory } from 'react-router'

import '../../css/EditProfile.css'
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  InputLabel,
  Typography,
} from '@material-ui/core'

class EditProfile extends Component {
  constructor(props) {
    super(props)
    let user = this.props.auth.user
    this.state = {
      first_name:          user.first_name,
      last_name:           user.last_name,
      username:            user.username,
      usernameInUse:       '',
      custom_location:     user.custom_location || '',
      birth_date:          user.birth_date ? new Date(user.birth_date) : null,
      description:         user.description || '',
      email:               user.email || '',
      facebookUser:        user.email === null,
      phone_number:        user.phone_number || '',
      gender:              user.gender || 'Gender',
      new_password:        '',
      new_password_repeat: '',
      privacy_mode:        user.privacy_mode,
      isValid:             true,
      editedData:          {},
      formData:            null,
      submitingData:       false,
      open:                false,
      errors:              {},
    }

    this.handleBirthdateInput = this.handleBirthdateInput.bind(this)
    this.handleGenderInput = this.handleGenderInput.bind(this)
    this.handleAvatarInput = this.handleAvatarInput.bind(this)
    this.handleSubmitEditedData = this.handleSubmitEditedData.bind(this)

    this.handleInput = this.handleInput.bind(this)
    this.handlePrivacyModeInput = this.handlePrivacyModeInput.bind(this)
    this.handleAccountDeactivate = this.handleAccountDeactivate.bind(this)
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleAccountDeactivate() {
    this.props.toggleActivation(this.props.auth.user.id).then(res => {
      Alert.success('Profile successfully Deactivated', {
        position: 'top-right',
        effect:   'slide',
        beep:     false,
        timeout:  3000,
        offset:   100,
      })
    })
  }

  setInitialState() {
    let initialState = Object.assign({}, this.state)

    if (initialState.birth_date) {
      initialState.birth_date = new Date(initialState.birth_date).toISOString()
    }

    this.setState({ initialState })
  }

  componentDidMount() {
    this.setInitialState()
  }

  handleEditedData(name, value) {
    const { initialState } = this.state
    let editedData = Object.assign({}, this.state.editedData)
    if (value !== initialState[name]) editedData[name] = value
    else delete editedData[name]

    return editedData
  }

  handleInput(event) {
    const { value, name } = event.target
    const editedData = this.handleEditedData(name, value.trim())
    this.setState(
      {
        [name]: value,
        editedData,
      },
      this.validateEdit
    )
  }

  handleBirthdateInput(event, birth_date) {
    let parsedBirthDate = new Date(birth_date)
    const editedData = this.handleEditedData(
      'birth_date',
      new Date(
        parsedBirthDate.valueOf() - parsedBirthDate.getTimezoneOffset() * 60000
      ).toISOString()
    )
    this.setState({ birth_date, editedData }, this.validateEdit)
  }

  handleGenderInput(event) {
    const gender = event.target.value
    const editedData = this.handleEditedData('gender', gender)
    this.setState({ gender, editedData }, this.validateEdit)
  }

  handlePrivacyModeInput(event) {
    const privacy_mode = event.target.value
    const editedData = this.handleEditedData('privacy_mode', privacy_mode)
    this.setState({ privacy_mode, editedData }, this.validateEdit)
  }

  handleAvatarInput(file) {
    let formData = null
    if (file) {
      formData = new FormData() // eslint-disable-line no-undef
      formData.append('avatar', file)
    }
    this.setState({ formData }, this.validateEdit)
  }

  validateEdit() {
    const { isValid, errors } = editValidation(this.state)
    this.setState({ isValid, errors })
  }

  _objectToFormData(obj, formdata) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        formdata.append(key, obj[key]) // Representation Object in form data
      }
    }

    return formdata
  }

  handleSubmitEditedData() {
    const { editedData, formData } = this.state
    const { editProfile } = this.props
    let data = Object.assign(
      {
        // password: editedData.new_password ? editedData.new_password : null,
      }, // no need actually, bc password is set not here
      editedData
    )
    delete data['new_password_repeat']
    delete data['new_password']

    if (formData) data = this._objectToFormData(data, formData)

    editProfile(data).then(response => {
      this._imageUpload.cancelAvatarSelection()
      this.setState(
        {
          submitingData:       false,
          editedData:          {},
          formData:            null,
          new_password:        '',
          new_password_repeat: '',
        },
        this.setInitialState
      )

      Alert.success('Profile successfully edited', {
        position: 'top-right',
        effect:   'slide',
        beep:     false,
        timeout:  3000,
        offset:   100,
      })
    })

    // after async
    this.setState({
      isValid:       false,
      submitingData: true,
    })
  }

  render() {
    const {
      email,
      first_name,
      last_name,
      username,
      custom_location,
      description,
      phone_number,
      gender,
      formData,
      birth_date,
      privacy_mode,
      submitingData,
      isValid,
      errors,
      editedData,
    } = this.state

    const { user } = this.props.auth

    const actions = [
      <Button color="primary" onClick={this.handleClose}>
        Cancel
      </Button>,
      <Button color="primary" onClick={this.handleAccountDeactivate}>
        Deactivate
      </Button>,
    ]

    const genders = ['Gender', 'Male', 'Female'].map(gender => (
      <MenuItem key={gender} value={gender}>
        {gender || 'Gender'}
      </MenuItem>
    ))

    const privacy_modes = ['public', 'nuuz_only', 'private'].map(mode => (
      <MenuItem key={mode} value={mode}>
        {mode}
      </MenuItem>
    ))

    return (
      <div className="profile-edit-container">
        <div className="profile-edit-image-container">
          <ImageUpload
            currentImage={user.avatar.url}
            handleAvatarInput={this.handleAvatarInput}
            ref={input => {
              this._imageUpload = input
            }}
            className="profile-edit-image"
          />
        </div>
        <form onSubmit={e => e.preventDefault()}>
          <div className="profile-edit-textfields">
            <TextField
              label="First name"
              name="first_name"
              value={first_name}
              className={classnames({
                'invalid-input': errors.first_name,
              })}
              onChange={this.handleInput}
              {...textToError(errors.first_name)}
            />
            <TextField
              label="Last name"
              name="last_name"
              value={last_name}
              className={classnames({
                'invalid-input': errors.last_name,
              })}
              onChange={this.handleInput}
              {...textToError(errors.last_name)}
            />
            <TextField
              label="Username"
              name="username"
              value={username}
              className={classnames({
                'invalid-input': errors.username,
              })}
              {...textToError(errors.username)}
              onChange={this.handleInput}
              onBlur={this.handleInput}
            />
            <TextField
              label="Location"
              name="custom_location"
              value={custom_location}
              onChange={this.handleInput}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                // disableToolbar decided to leave the toolbar
                label="Birth Date"
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                value={birth_date}
                className="profile-edit-birthdate"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                onChange={this.handleBirthdateInput}
              />
            </MuiPickersUtilsProvider>
            <TextField
              label="Bio"
              multiLine={true}
              rows={2}
              name="description"
              value={description}
              onChange={this.handleInput}
              className="profile-edit-bio"
              onFocus={this.moveCaretAtEnd}
            />
            <h4>Private information</h4>
            <TextField
              label="Email"
              value={email}
              className={classnames({
                'invalid-input': errors.email,
              })}
              type="email"
              onChange={this.handleInput}
              name="email"
              {...textToError(errors.email)}
            />
            <TextField
              label="Phone number"
              value={phone_number}
              type="tel"
              name="phone_number"
              onChange={this.handleInput}
            />
            <InputLabel id="select-gender-label">Gender</InputLabel>
            <Select
              labelId="select-gender-label"
              id="select-gender-field"
              value={gender || ''}
              onChange={this.handleGenderInput}
              selectedMenuItemStyle={{ color: '#6888ad' }}
            >
              {genders}
            </Select>

            <InputLabel id="select-privacy-label">Privacy mode</InputLabel>
            <Select
              labelId="select-privacy-label"
              id="select-privacy-field"
              value={privacy_mode}
              onChange={this.handlePrivacyModeInput}
              selectedMenuItemStyle={{ color: '#6888ad' }}
            >
              {privacy_modes}
            </Select>

            <h6>
              <Typography>
                <font color="red">
                  {' '}
                  When your account is private, only people you
                </font>
              </Typography>
              <Typography>
                <font color="red">
                  approve can follow you and view your nuuz
                </font>
              </Typography>
            </h6>

            <h6>
              <Button
                variant="contained"
                className="edit-profile__blocked-users-button"
                onClick={() => browserHistory.push('/blacklist')}
              >
                Blocked users
              </Button>
            </h6>

            <Button
              variant="contained"
              color="primary"
              className="edit-profile__change-password-button"
              onClick={() => browserHistory.push('/editpassword')}
            >
              Change password
            </Button>

            <div className="edit-profile-parent-submit-container">
              <Button
                variant="contained"
                className="edit-profile-parent-deactivate-button"
                onClick={this.handleOpen}
              >
                deactivate account
              </Button>

              <div className="edit-profile-submit-container pull-right">
                <Button
                  variant="contained"
                  disabled={submitingData}
                  className="send-comment edit-profile-submit edit-profile-cancel"
                  onClick={() => browserHistory.push(`/users/${user.id}`)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={
                    !isValid ||
                    (isEmpty(editedData) && !formData) ||
                    submitingData
                  }
                  color="primary"
                  onClick={this.handleSubmitEditedData}
                  className="send-comment edit-profile-submit"
                  startIcon={
                    submitingData ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : null
                  }
                >
                  {submitingData ? 'Saving...' : 'Save'}
                </Button>
              </div>
            </div>
          </div>
        </form>

        <Dialog
          aria-aria-labelledby="DEACTIVATE ACCOUNT"
          modal={false}
          open={this.state.open}
          onClose={this.handleClose}
        >
          <DialogTitle>DEACTIVATE ACCOUNT</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to deactivate your account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>{actions}</DialogActions>
        </Dialog>
      </div>
    )
  }
}

EditProfile.propTypes = {
  auth:        PropTypes.object.isRequired,
  editProfile: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  }
}
export default connect(mapStateToProps, {
  editProfile,
  checkUsername,
  toggleActivation,
})(EditProfile)
