import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { Link } from 'react-router'

import Popover from '@material-ui/core/Popover'

import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import isEmpty from 'lodash/isEmpty'

const avatarStyle = { width: '45px', height: '45px' }

export default class UserItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDropDown: false,
    }
    this.handleOpenDropDown = this.handleOpenDropDown.bind(this)
    this.handleCloseDropDown = this.handleCloseDropDown.bind(this)
  }

  handleOpenDropDown(event) {
    event.preventDefault()
    this.setState({
      openDropDown:  true,
      anchorElement: event.currentTarget,
    })
  }

  handleCloseDropDown() {
    this.setState({
      openDropDown: false,
    })
  }

  render() {
    const {
      user,
      menuItems,
      currentUser,
      showUser,
      setShowUserCurrentPosition,
    } = this.props
    const { openDropDown, anchorElement } = this.state
    let menuButton = null
    let popover = null
    if (menuItems) {
      menuButton = (
        <i
          className="fa fa-ellipsis-v user-item-dropdown"
          onClick={this.handleOpenDropDown}
        />
      )
      console.log('Components/UserItem.js Popover open:', openDropDown)
      popover = (
        <Popover
          className="popover-action"
          open={openDropDown}
          anchorEl={anchorElement}
          anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          onClose={this.handleCloseDropDown}
        >
          {menuItems.map((item, index) => {
            let menuItem = null
            if (
              (item.currentUser && showUser.id === currentUser.id) ||
              !item.currentUser
            ) {
              menuItem = (
                <MenuItem
                  key={index}
                  onClick={() =>
                    this.setState(
                      { openDropDown: false },
                      item.handler.bind(null, user.id)
                    )
                  }
                >
                  {item.label}
                </MenuItem>
              )
            }
            return menuItem
          })}
        </Popover>
      )
    }

    return (
      <div className="user-item">
        <div className="user-item-content">
          <Link
            to={`/users/${user.id}`}
            className="user-info-avatar"
            onClick={e => setShowUserCurrentPosition(null)}
          >
            <Avatar src={user.avatar.url} style={avatarStyle} />
          </Link>
          <div className="container">
            <Typography className="user-item-info">
              <Link
                to={`/users/${user.id}`}
                className="user-info-name"
                onClick={e => setShowUserCurrentPosition(null)}
              >
                {user.first_name + ' ' + user.last_name}
              </Link>
            </Typography>
            <Typography className="user-item-info">
              <span className="user-info-username" style={{ color: '#030303' }}>
                @{user.username}
              </span>
            </Typography>
          </div>
        </div>
        {user.id !== currentUser.id && !isEmpty(menuItems) && menuButton}
        {user.id !== currentUser.id && !isEmpty(menuItems) && popover}
        <Divider inset="true" />
      </div>
    )
  }
}
