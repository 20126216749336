/**
 * Created by boris on 4/25/17.
 */
import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { connect } from 'react-redux'
import {
  getUserPublications,
  setPublicationsOrder,
  deletePublication,
  searchPublications,
} from '../actions/PublicationsActions'
import ContentLoading from './ContentLoading'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'

import TextField from '@material-ui/core/TextField'

import '../css/Publications.css'
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

class SortableItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openConfirmWindow: false,
    }

    this.handleCloseDialog = this.handleCloseDialog.bind(this)
    this.deleteEvent = this.deleteEvent.bind(this)
    this.handleOpenDialog = this.handleOpenDialog.bind(this)
  }

  handleCloseDialog() {
    this.setState({ openConfirmWindow: false })
  }

  handleOpenDialog() {
    this.setState({ openConfirmWindow: true })
  }

  deleteEvent() {
    const { deletePublication, value } = this.props
    deletePublication(value.id)
    this.handleCloseDialog()
  }

  render() {
    const { value } = this.props

    const actions = [
      <Button color="primary" onClick={this.handleCloseDialog}>
        Cancel
      </Button>,
      <Button color="primary" focusVisible={true} onClick={this.deleteEvent}>
        Delete
      </Button>,
    ]

    const confirmWindow = (
      <Dialog
        aria-labelledby="Delete publication site"
        modal={false}
        open={this.state.openConfirmWindow}
        onClose={this.handleClose}
      >
        <DialogTitle>Delete publication site</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want delete <b>{value.name}</b> from your
            publication sites list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    )

    return React.createElement(
      SortableElement(() => (
        <div className="publications-image-container">
          <i
            className="fa fa-times delete-publication"
            aria-hidden="true"
            onClick={this.handleOpenDialog}
          />
          <a href={value.url} rel="noopener noreferrer" target="_blank">
            <Paper elevation={1} className="publications-image">
              <img src={value.icon.url} alt={value.name} />
              <span className="publication-title">{value.name}</span>
            </Paper>
          </a>
          {confirmWindow}
        </div>
      )),
      this.props
    )
  }
}

const SortableList = SortableContainer(({ items, deletePublication }) => {
  return (
    <div className="sortable-list">
      {items.length === 0 ? (
        <Typography className="no-stories no-publications">
          No publication sites to display
        </Typography>
      ) : (
        items.map((value, index) => (
          <SortableItem
            key={value.icon.url}
            index={index}
            deletePublication={deletePublication}
            value={value}
          />
        ))
      )}
    </div>
  )
})

class PublicationsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
    }
  }

  handlePublicationSearch(event) {
    const { searchPublications } = this.props
    this.setState(
      {
        value: event.target.value,
      },
      () => searchPublications(this.state.value)
    )
  }

  componentWillMount() {
    this.props.getUserPublications()
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { setPublicationsOrder, items } = this.props
    setPublicationsOrder(oldIndex, newIndex, [...items.values()][oldIndex].id)
  }

  render() {
    const { value } = this.state
    const { items, isLoading, deletePublication } = this.props

    return (
      <div className="page-content page-content-full-height">
        <Paper className="publications-page-content">
          <Typography className="subheader-20">Publication sites</Typography>
          <TextField
            value={value}
            placeholder="Search..."
            name="peopleText"
            className="search-tabs-searchfield publications-search"
            onChange={this.handlePublicationSearch.bind(this)}
          />
          {isLoading ? (
            <ContentLoading />
          ) : (
            <SortableList
              items={[...items.values()]}
              onSortEnd={this.onSortEnd}
              axis="xy"
              transitionDuration={500}
              deletePublication={deletePublication}
              pressDelay={200}
            />
          )}
        </Paper>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    items:      state.publications.items,
    isLoading:  state.publications.isLoading,
    pagination: state.publications.pagination,
  }
}

export default connect(mapStateToProps, {
  getUserPublications,
  setPublicationsOrder,
  deletePublication,
  searchPublications,
})(PublicationsPage)
